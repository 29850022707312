import React from 'react'
import ReactDOM from 'react-dom'
import Modal from 'react-modal'
import {Provider} from 'react-redux'
import store from './store'
import {App} from 'components'

import './assets/styles/app.css'

/**
 * Required for accessibility reasons.
 * @see http://reactcommunity.org/react-modal/accessibility/
 */
Modal.setAppElement('#root')

if (process.env.NODE_ENV === 'production') {
  console.log = function() {}
}

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)
