import {FieldTemplate} from '@contractool/schema'
import * as React from 'react'
import {Form} from 'components/Form'
import {isAllowedForUser} from 'utils/form'
import {fieldDependsOnOtherThan, fieldIsOnCard} from 'features/form/field'
import {GuardContext} from 'contexts'
import {useContext} from 'react'

function CustomField({
  field,
  option,
  className,
  config,
  required
}: {
    field: FieldTemplate;
    option: string;
    className?: string;
    config?: any;
    required?: boolean;
}) {
  const {user} = useContext(GuardContext)
  if (!field) {
    return <div>Missing field in fields.php</div>
  }
  if (!isAllowedForUser(field, user)) {
    return null
  }
  let require = field.required
  if (required) {
    require = required
  }

  let legend = ''
  if (field.legend) {
    legend = field.legend
  }
  switch (field.type) {
    case 'CURRENCY':
      return (
        <Form.CurrencyInput
          key={field.name}
          name={option + field.name}
          label={field.label}
          legend={field.legend ? field.legend : ''}
          className={className}
          required={require}
          {...field.props}
        />
      )
    case 'INPUT':
      return field.name.includes('email') ? (
        <Form.TextInput
          key={field.name}
          name={option + field.name}
          label={field.label}
          legend={legend}
          type="email"
          className={className}
          required={require}
          {...field.props}
        />
      ) : (
        <Form.TextInput
          key={field.name}
          name={option + field.name}
          label={field.label}
          legend={legend}
          className={className}
          required={require}
          {...field.props}
        />
      )
    case 'SELECT':
      if (config) {
        if (config.type === 'DEPENDING') {
          return (
            <Form.DependingDropdown
              key={field.name}
              name={option + field.name}
              label={field.label}
              options={config.options}
              dependencies={config.dependencies}
              alt={config.alt}
              className={className}
              required={require}
              {...field.props}
            />
          )
        } else if (config.type === 'GENERATING') {
          return (
            <Form.GeneratingDropdown
              key={field.name}
              name={option + field.name}
              label={field.label}
              options={field.values}
              generate={config.generate}
              className={className}
              required={require}
              {...field.props}
            />
          )
        }
      }

      return (
        <Form.Dropdown
          autocomplete={true}
          key={field.name}
          name={option + field.name}
          label={field.label}
          options={field.values}
          className={className}
          required={require}
          {...field.props}
        />
      )
    case 'DATE':
      return (
        <Form.DatePicker
          key={field.name}
          name={option + field.name}
          label={field.label}
          formatInput="dd MMM yyyy"
          className={className}
          required={require}
          {...field.props}
        />
      )
    case 'BOOLEAN_SELECT':
      return (
        <Form.Dropdown
          key={field.name}
          name={option + field.name}
          options={field.values}
          label={field.label}
          className={className}
          required={require}
          {...field.props}
        />
      )
    case 'CHECKBOX':
      return (
        <Form.Switch.Single
          key={field.name}
          name={option + field.name}
          label={field.label}
          className={className}
          {...field.props}
        />
      )
    case 'TEXT':
      return (
        <Form.TextArea
          key={field.name}
          name={option + field.name}
          label={field.label}
          legend={legend}
          rows={5}
          required={require}
          className={className}
          {...field.props}
        />
      )
    case 'MULTI_SELECT':
      return (
        <Form.Multiselect
          key={field.name}
          name={option + field.name}
          options={field.values}
          label={field.label}
          placeholder={field.label}
          dropdownClassName="justify-between border-b border-gray-200 focus:border-blue-700 py-6"
          className={className}
          autocomplete
          required={require}
          {...field.props}
        />
      )
    case 'FORMULA':
      return (
        <Form.Formula
          key={field.name}
          name={option + field.name}
          label={field.label}
          placeholder={field.label}
          formula={field.formula}
          className={className}
          {...field.props}
        />
      )
    case 'BIG_ADVANCED_RADIO':
      return (
        <Form.Radio.BigAdvanced
          key={field.name}
          name={option + field.name}
          label={field.label}
          options={field.advancedRadioValues}
          className={className}
        />
      )
    case 'SMALL_ADVANCED_RADIO':
      return (
        <Form.Radio.SmallAdvanced
          key={field.name}
          name={option + field.name}
          label={field.label}
          options={field.advancedRadioValues}
          className={className}
        />
      )
    case 'MONTHLY_SAVINGS':
      return (
        <Form.MonthlySavings
          key={field.name}
          name={field.name}
          label={field.label}
          className={className}
        />
      )
    default:
      return (
        <Form.TextInput
          key={field.name}
          name={option + field.name}
          label={field.label}
          legend={legend}
          className={className}
          required={require}
          {...field.props}
        />
      )
  }
}

const DependentCustomFieldOnCard = fieldIsOnCard(fieldDependsOnOtherThan(CustomField))

export default DependentCustomFieldOnCard
