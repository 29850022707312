import * as React from 'react'
import {Switch, Redirect, RouteProps, Route, useLocation} from 'react-router-dom'

import {AuthContext} from 'contexts'
import AuthorizationGuard from 'components/AuthorizationGuard'
import {Sidebar} from 'components/layout/Sidebar'
import {Navbar} from 'components/layout/Navbar'

import {ProjectsPage} from 'views/projects/ProjectsPage'
import ReportsPage from 'views/reports'
import {SuppliersPage} from 'views/suppliers/SuppliersPage'
import {TeamPage} from 'views/team/TeamPage'
import {DesignSystem} from 'views/ui/DesignSystem'
import {ProjectDetail} from 'views/projects/ProjectDetail'
import {NotificationsPage} from 'views/notifications/NotificationsPage'
import {NewProject} from 'views/newproject/NewProject'
import {NewProjectForm} from 'views/newproject/NewProjectForm'
import {Library} from 'views/Library'
import {ClauseLibrary} from 'views/ClauseLibrary'
import {Calendar} from 'views/calendar/Calendar'
import {NewsPage} from 'views/news/NewsPage'
import {RepositoryPage} from 'views/repository/RepositoryPage'

function Shell() {
  return (
    <AuthorizationGuard>
      <div className="fixed w-full h-full flex">
        <Sidebar />
        <div className="flex flex-col flex-auto" style={{minWidth: 0}}>
          <div className="h-20 flex-shrink-0 bg-white border-b">
            <Navbar />
          </div>

          <main className="h-full overflow-y-auto">
            <Switch>
              <PrivateRoute path="/projects/new/form">
                <NewProjectForm />
              </PrivateRoute>

              <PrivateRoute exact path="/projects/new">
                <NewProject />
              </PrivateRoute>

              <PrivateRoute exact path="/projects">
                <ProjectsPage />
              </PrivateRoute>

              <PrivateRoute path="/repository">
                <RepositoryPage />
              </PrivateRoute>

              <PrivateRoute path="/reports">
                <ReportsPage />
              </PrivateRoute>

              <PrivateRoute path="/projects/filters">
                <ProjectsPage />
              </PrivateRoute>

              <PrivateRoute path="/projects/categories">
                <ProjectsPage />
              </PrivateRoute>

              <PrivateRoute path="/library">
                <Library />
              </PrivateRoute>

              <PrivateRoute path="/clauses">
                <ClauseLibrary />
              </PrivateRoute>

              <PrivateRoute path="/calendar">
                <Calendar />
              </PrivateRoute>

              <PrivateRoute path="/projects/:id">
                <ProjectDetail />
              </PrivateRoute>

              <PrivateRoute path="/suppliers">
                <SuppliersPage />
              </PrivateRoute>

              <PrivateRoute path="/team">
                <TeamPage />
              </PrivateRoute>

              <PrivateRoute path="/news">
                <NewsPage />
              </PrivateRoute>

              <PrivateRoute path="/notifications">
                <NotificationsPage />
              </PrivateRoute>

              {/* ui lib should be accessible in dev environment
                            and CI as some cypress test run against it */}
              <PrivateRoute path="/ui">
                <DesignSystem />
              </PrivateRoute>

              <PrivateRoute path="*">
                <Redirect to="/projects" />
              </PrivateRoute>
            </Switch>
          </main>
        </div>
      </div>
    </AuthorizationGuard>
  )
}

const PrivateRoute: React.FC<RouteProps> = (props: RouteProps) => {
  const {loggedIn} = React.useContext(AuthContext)
  const location = useLocation()

  return loggedIn ? <Route {...props} /> : <Redirect to={`/login?redirectTo=${location.pathname}`} />
}

export default Shell
