import React, {FC, useContext, useEffect, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import {Form} from 'components/Form'
import {AppContext} from 'contexts'
import {CustomField} from 'components'
import {getOptions, TeamRoleSelect} from 'utils/form'
import {useRequest} from 'hooks/useRequest'

import {FieldTemplate, Project, ProjectType, ProjectCategory, Role} from '@contractool/schema'
import SupplierSelect from 'components/form/SupplierSelect'
import {EventEmitter} from 'utils/eventEmitter'
import {translate} from 'utils/translations'
import {Mutable as ProjectTitle} from 'components/form/ProjectTitle'
import ProjectFormContext from 'views/project/ProjectFormContext'

export const DefaultForm: FC<{project: Project}> = ({project}) => {
  const {create, supplierTitle} = React.useContext(ProjectFormContext)
  const [categories] = useRequest<ProjectCategory[]>('/api/project-categories', [])
  const [types] = useRequest<ProjectType[]>('/api/project-types', [])
  const categoriesAsOptions = getOptions(categories)
  const typesAsOptions = getOptions(types)

  const {config} = useContext(AppContext)

  const fields = config.fields
    ? config.fields.filter((f: FieldTemplate) => !f.hidden && f.workflow === project.workflow)
    : []

  const [effectiveDate, setEffectiveDate] = useState<string>('')
  const [contractType, setContractType] = useState<string[]>([])
  const [category, setCategory] = useState<string>('')
  const [region, setRegion] = useState<string>('')
  const [country, setCountry] = useState<string>('')

  EventEmitter.subscribe('datepicker.finishes_at', setEffectiveDate)
  EventEmitter.subscribe('dropdown.category_id', setCategory)
  EventEmitter.subscribe('dropdown.fields.region', setRegion)
  EventEmitter.subscribe('dropdown.fields.country', setCountry)
  EventEmitter.subscribe('multiselect.types', (types: any[]) => {
    setContractType(types.map((t) => t.title))
  })

  useEffect(() => {
    if (!create) {
      return
    }
    let parts = []
    if (supplierTitle) {
      parts.push(supplierTitle)
    }
    if (effectiveDate) {
      parts.push(effectiveDate)
    }
    if (category) {
      parts.push(category)
    }
    if (region) {
      parts.push(region)
    }
    if (country) {
      parts.push(country)
    }
    if (contractType.length > 0) {
      parts.push(contractType.join('_'))
    }

    let title = parts.join(' ')
    EventEmitter.dispatch('new-project.set-form-values', {
      title: title
    })
  }, [supplierTitle, contractType, category, region, country, effectiveDate, create])

  let groups: any = {}
  for (let f of fields) {
    if (f.group !== '') {
      groups[f.group] = f.group
    }
  }

  const history = useHistory()

  return project ? (
    <>
      {config.roles && fields && (
        <>
          <ProjectTitle className="mb-8" />
          <div className="divide-y divide-gray-100">
            <div className="py-8 w-full">
              {categories.length > 0 && (
                <Form.Dropdown
                  name="category_id"
                  className="mb-6"
                  label={translate(config.integration === 'dell_ip' ? 'Category L1' : 'Spend Category')}
                  options={categoriesAsOptions}
                  required={true}
                />
              )}
              <SupplierSelect />
              {types.length > 0 && (
                <Form.Multiselect
                  name="types"
                  label={translate('Project Types')}
                  options={typesAsOptions}
                  autocomplete
                  placeholder={translate('Project Types')}
                  dropdownClassName="justify-between border-b border-gray-200 focus:border-blue-700 py-6"
                  className="mt-6"
                  changeKeys={{key1: 'id', key2: 'title'}}
                  required={true}
                />
              )}
              {/*<Form.DatePicker*/}
              {/*    name="finishes_at"*/}
              {/*    label={translate('Target Date for completion')}*/}
              {/*    className="mt-6"*/}
              {/*    required={true}*/}
              {/*/>*/}
              {fields
                .filter((f: FieldTemplate) => f.group === '')
                .map((field: FieldTemplate) => (
                  <CustomField
                    key={`${field.name}`}
                    field={field}
                    option="fields."
                    className="mt-6"
                  />
                ))}
              {create && config.roles
                .map(
                  (role: Role) =>
                    role.showInForm &&
                    role.workflow === project.workflow &&
                    role.key !== 'admin' &&
                    role.key !== 'requestor' &&
                    role.key !== 'assignee' && (
                      <TeamRoleSelect
                        key={role.key}
                        role={role}
                        right={role.side_link ? <Link className="text-blue-600 hover:text-blue-300" to={`${role.side_link.url}${history.location.search}`}>{role.side_link.text}</Link> : null}
                        className="mt-6"
                        groups={role.groups}
                      />
                    )
                )}
            </div>
            {Object.keys(groups).map((group: string) => {
              return (
                <div key={group}>
                  <h1 className="text-xl">{group}</h1>
                  <div className="py-8 w-full">
                    {fields
                      .filter((f: FieldTemplate) => f.group === group)
                      .map((field: FieldTemplate) => (
                        <CustomField
                          key={`${field.name}`}
                          field={field}
                          option="fields."
                          className="mt-6"
                        />
                      ))}
                  </div>
                </div>
              )
            })}
            {/*{fields.map((arr:any, index:number) => (*/}
            {/*<div*/}
            {/*key={`rowdiv${index}`}*/}
            {/*className="flex flex-wrap divide-x divide-gray-100"*/}
            {/*>*/}
          </div>
          {/*))}*/}
          {/*</div>*/}
        </>
      )}
    </>
  ) : null
}
