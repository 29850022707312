import React, {FC, useState} from 'react'

import {Button} from 'components/Button'

interface IFieldCardProps {
    name: string
    value: any
}

const FieldCard: FC<IFieldCardProps> = ({name, value, children}) => {
  const [isEditMode, setEditMode] = useState(false)

  return (
    <div className="border flex p-5 rounded-lg">
      <div className="flex-1">
        {isEditMode ? (
          <>
            {children}
          </>
        ) : (
          <>
            <div className="mb-5">{name}</div>
            <div>{value}</div>
          </>
        )}
      </div>
      <div>
        <Button onClick={() => setEditMode(mode => !mode)}>
          {isEditMode ? 'Save' : 'Edit'}
        </Button>
      </div>
    </div>
  )
}

export default FieldCard
