import React, {FC, useContext} from 'react'
import {Project} from '@contractool/schema'
import {SidePanel} from './ProjectDetail'
import {useDropzone} from 'react-dropzone'
import {useUploadingReducer} from '../../components/Avatar'
import {CircularProgressbar, buildStyles} from 'react-circular-progressbar'
import {upload} from '../../utils/upload'
import {http, Response} from '../../utils/http'
import {Icon} from '../../components/Icon'
import {parseISO, format} from 'date-fns'
import {Link} from 'react-router-dom'
import {Route, Switch} from 'react-router'
import {ProjectDocumentDetail} from './ProjectDocumentDetail'
import {Document} from '@contractool/schema'
import {sign} from '../../utils/auth'
import {translate} from 'utils/translations'
import {AppContext} from 'contexts'
import {iconHandler} from './ProjectLogs/DocumentLog'
import {permissionRequest} from '../../utils/wildcard'
import {AddFiles} from 'views/projects/AddFiles'

/* returns main document as Attachment */
const mainDocument = (project: Project): Document | null => {
  const attachments = project.attachments.filter(
    (value) => project.document_id + '' === value.id + ''
  )
  if (attachments.length === 1) return attachments[0]

  return null
}
export const ContractPanel: FC<{
    project: Project;
    onUpdate: () => void;
    onClose: () => void;
}> = ({project, onUpdate, onClose}) => {
  const {config} = useContext(AppContext)

  const refresh = () => {
    onClose()
    onUpdate()
  }

  if (!config.config) {
    return null
  }
  const onSend = (file: {path: string; name: string}) => {
    let currentMainDocument = mainDocument(project)
    const data = {...file, project_document: true}
    if (currentMainDocument === null) {
      return http.post<Document>(project.attachments_url, {file: data})
    }

    return http.post<Document>(currentMainDocument.versions_url, {file: data})
  }

  let permissions = project.can

  return (
    <SidePanel
      heading={translate('Contract')}
      right={
        <>
          {config.config.office365 ? (
            <a
              href={project.new_contract_url}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center text-blue-700 mr-4"
            >
              <Icon name="word" size={5} />
              <span className="ml-2">{translate('Create online')}</span>
            </a>
          ) : null}
          {permissionRequest(permissions, 'project.widget.documents.edit') && (
            <Link
              to={`/projects/${project.id}/contract/browse`}
              className="flex items-center text-blue-700"
            >
              <Icon name="add" size={5} />
              <span className="ml-2">{translate('Add file')}</span>
            </Link>
          )}
        </>
      }
    >
      <ContractDocument project={project}></ContractDocument>
      {permissionRequest(permissions, 'project.widget.documents.edit') ? (
        <ContractUpload onUpload={refresh} onSend={onSend} />
      ) : null}
      <Switch>
        <Route path={`/projects/${project.id}/documents/:documentId`}>
          <ProjectDocumentDetail onUpdate={refresh} onClose={onClose} project={project} />
        </Route>
        {permissionRequest(permissions, 'project.widget.documents.edit') && (
          <Route exact path={`/projects/${project.id}/contract/browse`}>
            <AddFiles
              type="main"
              onUpdate={refresh}
              onClose={onClose}
              project={project}
            />
          </Route>
        )}
      </Switch>
    </SidePanel>
  )
}

const ContractDocument: FC<{project: Project}> = ({project}) => {
  const {config} = useContext(AppContext)

  if (project.attachments_url.length === 0) return null
  const projectDocument = mainDocument(project)
  if (projectDocument === null) return <></>
  let latestVersion = projectDocument.versions[projectDocument.versions.length - 1]
  let permissions = project.can
  let icon = iconHandler(projectDocument.title)

  return (
    <>
      <div className="border border-gray-200 p-6 mb-4 rounded-xl flex">
        {icon}
        <div className="pl-3">
          <Link to={`/projects/${project.id}/documents/${projectDocument.id}`}>
            <div className="leading-tighter">{projectDocument.title}</div>
            <div className="text-gray-600 text-sm pt-2">
              {translate('Version')} {projectDocument.versions.length} -{' '}
              {format(parseISO(latestVersion.created_at), 'dd. MMM')}
            </div>
          </Link>
        </div>
        <div className="flex-1 flex flex-row-reverse">
          {permissionRequest(permissions, 'project.widget.documents.view') && (
            <a
              href={sign(projectDocument.download_url)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="get_app" size={6} className="ml-3 text-gray-500" />
            </a>
          )}
          {config.config.office365 &&
                        permissionRequest(permissions, 'project.widget.documents.edit') && (
            <a
              href={sign(projectDocument.edit_url)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="edit" size={6} className="ml-3 text-gray-500" />
            </a>
          )}
          {permissionRequest(permissions, 'project.widget.documents.view') && (
            <a
              href={sign(projectDocument.preview_url)}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon name="remove_red_eye" size={6} className="ml-3 text-gray-500" />
            </a>
          )}
        </div>
      </div>
    </>
  )
}
export const ContractUpload: FC<{
    onSend: (file: {path: string; name: string}) => Promise<Response<any>>;
    singleFile?: boolean;
    onUpload?: (document: Document) => void;
    className?: string;
}> = ({onSend, onUpload, className, singleFile = false}) => {
  const [{uploading, progress}, dispatch] = useUploadingReducer()

  const startUpload = (file: File) => {
    console.log('START UPLOAD')
    dispatch({type: 'start_uploading'})

    upload(file, {
      onUploadProgress: (progress) => {
        dispatch({type: 'progress_uploading', progress})
      }
    })
      .then((file) => onSend(file))
      .then((response) => {
        dispatch({type: 'finish_uploading'})

        onUpload && onUpload(response.data)
      })
  }

  const {getRootProps, getInputProps} = useDropzone({
    onDrop: (files) => {
      if (singleFile === true) return startUpload(files[0])
      files.forEach((file) => startUpload(file))
    }
  })

  return (
    <>
      {uploading && (
        <div className="rounded-full w-full h-full bg-gray-000 flex items-center justify-center">
          <CircularProgressbar
            value={progress}
            className="w-6 h-6"
            strokeWidth={5}
            styles={buildStyles({
              pathTransitionDuration: 0.5,
              pathColor: '#2979ff',
              trailColor: '#e9ecef'
            })}
          />
        </div>
      )}
      <div
        className={`focus:outline-none ${className}`}
        {...getRootProps()}
        onClick={() => {}}
      >
        <input type="file" className="hidden" {...getInputProps()} />

        <div className="bg-gray-000 text-gray-600 p-7 rounded-xl flex items-center justify-center">
          <Icon name="cloud_upload" size={6} />
          <div className="pl-3">
            {translate('Drop file here or')}{' '}
            <span
              className="text-blue-700 cursor-pointer focus:outline-none"
              {...getRootProps({props: 'onClick'})}
            >
              {translate('browse it')}
            </span>
          </div>
        </div>
      </div>
    </>
  )
}
