import React, {
  FC,
  ReactNode,
  useState,
  ReactElement,
  Children,
  useEffect,
  useCallback,
  useReducer,
  Reducer
} from 'react'
import {Switch, Route, Redirect, useHistory, useRouteMatch} from 'react-router-dom'
import {EventEmitter} from '../utils/eventEmitter'

type TTab = {
    name: string;
    heading: ReactNode | ((props: {active: boolean}) => ReactNode);
    children?: ReactNode;
    className?: string;
};

export const Tabs: FC<{
    selected?: string;
    id?: string;
    onSelect?: (name: string) => void;
    className?: string;
    fixedHeight?: boolean
}> = ({children, className, onSelect, selected: initial, id, fixedHeight = true}) => {
  const tabs = Children.map(children || [], (child) => (child as ReactElement<TTab>).props)
  const [selected, setSelected] = useState(initial || (tabs[0] && tabs[0].name) || undefined)

  useEffect(() => {
    initial && setSelected(initial)
  }, [initial])

  EventEmitter.subscribe(`tab-select-${id}`, (tab: string) => {
    setSelected(tab)
  })

  return (
    <>
      <TabList
        tabs={tabs}
        selected={selected}
        onClick={(tab) => {
          setSelected(tab.name)
          onSelect && onSelect(tab.name)
        }}
        className={className}
        fixedHeight={fixedHeight}
      />

      {tabs.map((tab, i) => {
        return selected === tab.name && <TabPanel key={i} tab={tab} />
      })}
    </>
  )
}

export const RouteTabs: FC<{
    onSelect?: (name: string) => void;
    className?: string;
    match: string;
    fixedHeight?: boolean
}> = ({children, onSelect, match, className, fixedHeight = true}) => {
  const tabs = Children.map(children || [], (child) => (child as ReactElement<TTab>).props)

  const history = useHistory()
  const route = useRouteMatch<{tab: string}>({path: match})

  const url = (tab: TTab) => match.replace(':tab', tab.name)

  return (
    <>
      <TabList
        tabs={tabs}
        selected={route?.params.tab}
        onClick={(tab) => {
          if (onSelect) {
            onSelect(tab.name)
          } else {
            history.push(url(tab))
          }
        }}
        className={className}
        fixedHeight={fixedHeight}
      />

      <Switch>
        {tabs.map((tab, i) => (
          <Route path={url(tab)} key={i}>
            <TabPanel tab={tab} />
          </Route>
        ))}

        <Route>
          <Redirect to={url(tabs[0])} />
        </Route>
      </Switch>
    </>
  )
}

const TabList: FC<{
    tabs: TTab[];
    selected: string | undefined;
    onClick: (tab: TTab) => void;
    className?: string;
    fixedHeight: boolean
}> = ({tabs, selected, onClick, className, fixedHeight = true}) => {
  return (
    <ul className={`flex border-b ${fixedHeight ? 'h-14' : ''} ${className || ''}`} role="tablist">
      {tabs.map((tab, i) => {
        return (
          <li className="h-full" key={i}>
            <button
              role="tab"
              onClick={() => onClick(tab)}
              className={`relative h-full py-3 cursor-pointer border-b-2 select-none focus:outline-none focus:border-blue-700 hover:border-blue-700 ${
                selected === tab.name
                  ? 'text-gray-800 border-blue-700'
                  : 'text-gray-600 border-transparent-000'
              } ${tabs.length - 1 === i ? '' : 'mr-10'}`}
              id={aria_tab(tab.name)}
              aria-controls={aria_panel(tab.name)}
              aria-selected={selected === tab.name}
              data-cy-tab={tab.name}
              type="button"
            >
              {typeof tab.heading === 'function'
                ? tab.heading({active: selected === tab.name})
                : tab.heading}
            </button>
          </li>
        )
      })}
    </ul>
  )
}

const TabPanel: FC<{tab: TTab; className?: string}> = ({tab, className}) => {
  if (!tab.children) {
    return null
  }

  return (
    <div
      role="tabpanel"
      className={`${tab.className} ${className}`}
      id={aria_panel(tab.name)}
      aria-labelledby={aria_tab(tab.name)}
    >
      {tab.children}
    </div>
  )
}

export const Tab: FC<TTab> = ({children}) => {
  return <>{children}</>
}

function aria_panel(tab: string): string {
  return `tabs-${tab}-panel`
}

function aria_tab(tab: string): string {
  return `tabs-${tab}-tab`
}

type TabState = {
    tabs: TTab[];
    selected: string | undefined;
    animation: 'left' | 'right';
};

enum ActionType {
    SetSelected = 'SET_SELECTED',
}

type TabAction = {
    type: ActionType;
    payload: {
        selected: string;
    };
};

const tabsReducer: Reducer<TabState, TabAction> = (state, action) => {
  switch (action.type) {
    case ActionType.SetSelected:
      const currentIndex = state.tabs.findIndex((t) => t.name === state.selected)
      const selectedIndex = state.tabs.findIndex((t) => t.name === action.payload.selected)

      return {
        ...state,
        selected: action.payload.selected,
        animation: currentIndex > selectedIndex ? 'left' : 'right'
      }
    default:
      return {
        ...state
      }
  }
}

export const FormTabs: FC<{
    selected?: string;
    id?: string;
    onSelect?: (name: string) => void;
    className?: string;
}> = ({children, className, onSelect, selected: initial}) => {
  const tabs = Children.map(children || [], (child) => (child as ReactElement<TTab>).props)

  const [state, dispatch] = useReducer(tabsReducer, {
    tabs: tabs,
    selected: initial || (tabs[0] && tabs[0].name) || undefined,
    // prev: undefined,
    animation: 'right'
  })

  useEffect(() => {
    if (initial) {
      dispatch({
        type: ActionType.SetSelected,
        payload: {
          selected: initial
        }
      })
    }
  }, [initial])

  const onTabClick = useCallback(
    (tab) => {
      dispatch({
        type: ActionType.SetSelected,
        payload: {
          selected: tab.name
        }
      })
      onSelect && onSelect(tab.name)
    },
    [dispatch, onSelect]
  )

  return (
    <div className="overflow-x-hidden w-full">
      {tabs.map((tab, i) => {
        return (
          state.selected === tab.name && (
            <TabPanel
              key={i}
              tab={tab}
              className={
                state.animation === 'right'
                  ? 'animate-slide-in-right'
                  : 'animate-slide-in-left'
              }
            />
          )
        )
      })}
      <FormTabList
        tabs={tabs}
        selected={state.selected}
        onClick={onTabClick}
        className={className}
      />
    </div>
  )
}

const FormTabList: FC<{
    tabs: TTab[];
    selected: string | undefined;
    onClick: (tab: TTab) => void;
    className?: string;
}> = ({tabs, selected, onClick, className}) => {
  const [index, setIndex] = useState<number>(tabs.findIndex((t) => selected === t.name))

  useEffect(() => {
    setIndex(tabs.findIndex((t) => selected === t.name))
  }, [selected, tabs])

  const onEnter = (id: number) => {
    setIndex(id)
  }

  const onLeave = useCallback(() => {
    setIndex(tabs.findIndex((t) => selected === t.name))
  }, [selected, tabs])

  const cols = tabs.length

  return (
    <div className="w-full">
      <ul
        className={`grid grid-cols-${cols} w-full mb-3 h-14 ${className || ''}`}
        role="tablist"
      >
        {tabs.map((tab, i) => {
          return (
            <li
              className="h-full text-center"
              key={i}
              onMouseEnter={() => onEnter(i)}
              onMouseLeave={() => onLeave()}
            >
              <button
                role="tab"
                onClick={() => onClick(tab)}
                className={`relative h-full cursor-pointer select-none focus:outline-none hover:text-blue-700 ${
                  selected === tab.name ? 'text-blue-700' : 'text-gray-500'
                }`}
                id={aria_tab(tab.name)}
                aria-controls={aria_panel(tab.name)}
                aria-selected={selected === tab.name}
                data-cy-tab={tab.name}
                type="button"
              >
                {typeof tab.heading === 'function'
                  ? tab.heading({active: selected === tab.name})
                  : tab.heading}
              </button>
            </li>
          )
        })}
      </ul>
      <div
        className={`${
          index + 1 !== cols ? `w-${index + 1}/${cols}` : 'w-full'
        } bg-blue-700 h-px duration-700 easy-in-out`}
      />
    </div>
  )
}
