import {Project} from '@contractool/schema'
import React, {FC, useContext, useState} from 'react'

import {AppContext} from 'contexts'
import {Form} from 'components/Form'
import {Modal} from 'components/Modal'
import {Button} from 'components/Button'
import {useToasts} from 'hooks'
import {Form as DellSWPForm} from 'integrations/dell_swp/views/project/components'
import {EventEmitter} from 'utils/eventEmitter'
import {http} from 'utils/http'
import {translate} from 'utils/translations'

import {DellOnboardingDetailsForm, DellDefaultDetailsForm} from './DetailsForm/DellDetailsForm'
import {DefaultForm} from '../newproject/DefaultForm'
import {Form as DellJIForm} from 'integrations/dell_ji/components/views/project/components'
import {Form as ScantraxxForm} from 'integrations/scantraxx/components/views/project/components'
import ProjectFormProvider from 'views/project/ProjectFormProvider'

export const ProjectDetailsEdit = ({
  project,
  onUpdate,
  onClose
}: {
    project: Project;
    onUpdate: () => void;
    onClose: () => void;
}) => {
  const {success} = useToasts()
  const {config} = useContext(AppContext)

  const [initValues, setInitValues] = useState({...project})

  const projectChange = (project: Project) => {
    setInitValues({...project})
  }
  EventEmitter.subscribe('project-changed', projectChange)

  return (
    <Modal heading={translate('Edit Project Details')} onClose={onClose}>
      <Form
        initialValues={initValues}
        onSubmit={(values) => {
          const payload = JSON.parse(JSON.stringify(values))

          delete payload.logs_full
          delete payload.parent
          delete payload.children
          delete payload.child
          delete payload.team

          return http.put<Project>('/api/projects/' + project.id, payload)
        }}
        onSuccess={(project: Project) => {
          onClose()
          onUpdate()
          success(translate('Project Details of :title were updated successfully', {title: project.title}))
        }}
        loader={'big'}
      >
        {config.roles && config.fields && config.integration && (
          <DetailsForm project={project} />
        )}
        <Modal.Footer className="flex justify-between">
          <Button color="white" onClick={onClose}>
            {translate('Cancel')}
          </Button>

          <Form.Submit>{translate('Save Changes')}</Form.Submit>
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

const DetailsForm: FC<{project: Project}> = ({project}) => {
  const {config} = useContext(AppContext)

  return config.integration === 'dell_ji' ? (
    <ProjectFormProvider>
      <DellJIForm project={project} />
    </ProjectFormProvider>
  ) : config.integration === 'dell_swp' ? (
    <ProjectFormProvider>
      <DellSWPForm project={project} />
    </ProjectFormProvider>
  ) : config.integration === 'scantraxx' ? (
    <ProjectFormProvider>
      <ScantraxxForm project={project} />
    </ProjectFormProvider>
  ) : config.integration === 'dell' ? (
    project.workflow === 'onboarding' ? (
      <ProjectFormProvider>
        <DellOnboardingDetailsForm roles={config.roles} fields={config.fields} />
      </ProjectFormProvider>
    ) : (
      <ProjectFormProvider>
        <DellDefaultDetailsForm roles={config.roles} fields={config.fields} />
      </ProjectFormProvider>
    )
  ) : (
    <ProjectFormProvider>
      <DefaultForm project={project} />
    </ProjectFormProvider>
  )
}
