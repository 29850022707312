import * as React from 'react'

import {FormContext} from 'components/Form'
import {Option} from 'components/Dropdown'
import ProjectContext from 'views/projects/ProjectContext'

const fieldDependsOnOtherThan = (Component: React.ComponentType<any>) =>
  (props: any) => {
    const {field} = props
    const formContext = React.useContext(FormContext)
    const projectContext = React.useContext(ProjectContext)
    if (field.showOnlyIf) {
      let relatedValue = formContext.values[field.showOnlyIf.propertyName]

      if (typeof relatedValue === 'undefined' && typeof formContext.values.fields !== 'undefined') {
        relatedValue = formContext.values.fields[field.showOnlyIf.propertyName]
      }

      if (projectContext.resource !== null) {
        if (typeof relatedValue === 'undefined' && typeof projectContext.resource.fields !== 'undefined') {
          relatedValue = projectContext.resource.fields[field.showOnlyIf.propertyName]
        }

        if (field.showOnlyIf.propertyName === 'category_id') {
          relatedValue = projectContext.resource.category_id
        }
      }

      if (relatedValue === true) {
        relatedValue = '1'
      }
      if (relatedValue === false) {
        relatedValue = '0'
      }
      if (!field.showOnlyIf.value.includes('' + relatedValue)) {
        return null
      }
    }

    if (field.filterOptions) {

      let relatedValue = formContext.values.fields[field.filterOptions.propertyName]

      if (typeof relatedValue === 'undefined') {
        relatedValue = formContext.values[field.filterOptions.propertyName]
      }

      if (relatedValue === true) {
        relatedValue = '1'
      }
      if (relatedValue === false) {
        relatedValue = '0'
      }
      if ('' + relatedValue in field.filterOptions.conditions) {
        field.values = field.filterOptions.conditions[relatedValue] as Option<string>[]
      } else {
        field.values = []
      }
    }

    return <Component {...props} />
  }

export default fieldDependsOnOtherThan
