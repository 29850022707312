import React from 'react'
import {Project} from '@contractool/schema'
import {Page} from 'components/Page'
import {Pagination} from 'components/Pagination'
import {useRequest} from 'utils/hooks/useRequest'
import {useParams} from 'utils/hooks/useParams'
import {translate} from 'utils/translations'
import {SearchParams, SearchResults} from 'views/projects/ProjectsPage'
import {RepositoryList} from './RepositoryList'
import {RepositoryFilters} from './RepositoryFilter'

export function RepositoryPage() {
  const [params, {setParam, setParams}] = useParams<SearchParams>({
    phrase: '',
    page: 1,
    team: {assignee: []},
    states: ['executed'],
    categories: [],
    fields: {country: []},
    suppliers: [],
    filter_id: null,
    favorite: 1,
    milestone_quarter: [],
    workflow: []
  })

  const [loaded, setLoaded] = React.useState(false)
  const [projects, {pagination}] = useRequest<Project[], {total: number}>(
    '/api/projects',
    [],
    {params},
    setLoaded
  )

  return (
    <Page heading={translate('Repository')}>
      <RepositoryFilters params={params} setParams={setParams} setParam={setParam} />
      {params ? (
        <SearchResults phrase={params.phrase} projectLenght={projects.length} />
      ) : null}
      <RepositoryList projects={projects} loaded={loaded} />
      <div className="flex justify-center pt-16">
        <Pagination
          currentPage={pagination.currentPage}
          totalPages={pagination.totalPages}
          totalBlocks={7}
          onSelect={(page) => {
            setParam('page', page)
          }}
        />
      </div>
    </Page>
  )
}
