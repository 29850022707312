import {User} from '@contractool/schema'
import React, {FC, FormEvent} from 'react'
import {MentionsInput, Mention} from 'react-mentions'

import {AvatarLink, useUploadingReducer} from 'components/Avatar'
import {Icon} from 'components/Icon'
import {http, Response} from 'utils/http'
import {translate} from 'utils/translations'
import {CircularProgressbar, buildStyles} from 'react-circular-progressbar'
import {upload} from 'utils/upload'

export const CommentForm: FC<{
    comment: string;
    setComment: (comment: string) => void;
    submitHandler: (event: FormEvent<HTMLFormElement>) => void;
    onSendFile:(file: {path: string; name: string}) => Promise<Response<any>>;
}> = ({comment, setComment, submitHandler, onSendFile}) => {
  const loadUsers = (key: string, callback: any) => {
    http.get<User[]>('api/users', {phrase: key})
      .then((response) =>
        response.data.map((user) => ({
          display: user.name,
          id: `${user.avatar_url}|-|-||${user.username}`
        }))
      )
      .then(callback)
  }

  const commentHandler = (value: string) => {
    const modifiedComment = value
      .replace(
        /@\[[a-zA-Z0-9_\u00C0-\u017F]+ *[a-zA-Z0-9_\u00C0-\u017F]* *[a-zA-Z0-9_\u00C0-\u017F]*\]\(.+?\|-\|-\|/g,
        '@'
      )
      .replace(/@\|[a-zA-Z0-9_.]+\)/g, (username) => {
        return username.replace('|', '').slice(0, -1)
      })

    setComment(modifiedComment)
  }

  const sendOnEnter = (event: any) => {
    const keyNum = event.which || event.keyCode
    if (keyNum === 13 && event.ctrlKey) {
      submitHandler(event)
    }
  }

  const startUpload = (file: File) => {
    console.log('START UPLOAD')
    dispatch({type: 'start_uploading'})

    upload(file, {
      onUploadProgress: (progress) => {
        dispatch({type: 'progress_uploading', progress})
      }
    })
      .then((file) => {
        onSendFile(file)
      })
      .then((response) => {
        dispatch({type: 'finish_uploading'})
        console.log(response)
      })
  }

  const pasteAttachment = (pasteEvent:any) => {
    const item = pasteEvent.clipboardData.items[0]
    if (item.type.indexOf('image') === 0){
      startUpload(pasteEvent.clipboardData.files[0])
    }
  }
  const [{uploading, progress}, dispatch] = useUploadingReducer()

  // TODO: Fix MentionsInput resizing
  return (
    <form onSubmit={(e) => submitHandler(e)}>
      {uploading && (
        <div className="rounded-full w-full h-full bg-gray-000 flex items-center justify-center">
          <CircularProgressbar
            value={progress}
            className="w-6 h-6"
            strokeWidth={5}
            styles={buildStyles({
              pathTransitionDuration: 0.5,
              pathColor: '#2979ff',
              trailColor: '#e9ecef'
            })}
          />
        </div>
      )}
      <div className="flex bg-gray-000 rounded-lg p-5 mt-4 items-center">
        {!comment.length && <Icon name="chat" size={5} className="text-gray-500 mr-2" />}
        <MentionsInput
          value={comment}
          style={inputStyle}
          onChange={(event) => commentHandler(event.target.value)}
          onKeyPress={sendOnEnter}
          onPaste={pasteAttachment}
          placeholder={translate('Type here, use @ to mention somebody')}
        >
          <Mention
            trigger="@"
            data={loadUsers}
            displayTransform={(id) => `@${id.replace(/.+?(\|-\|-\|\|)/, '')}`}
            appendSpaceOnAdd
            renderSuggestion={(suggestion, _, highlightedDisplay) => (
              <div className="flex justify-left items-center">
                <AvatarLink
                  avatar_url={
                    typeof suggestion.id === 'number'
                      ? suggestion.id.toString().split('|-|-|')[0]
                      : suggestion.id.split('|-|-|')[0]
                  }
                  name={
                    typeof suggestion.id === 'number'
                      ? suggestion.id.toString().split('|-|-||')[1]
                      : suggestion.id.split('|-|-||')[1]
                  }
                  className="w-7 h-7 mr-3"
                />
                {highlightedDisplay}
              </div>
            )}
          />
        </MentionsInput>
        {!!comment.length && (
          <button
            type="submit"
            className="focus:outline-none text-blue-700 hover:text-blue-400"
          >
            {translate('Send')}
          </button>
        )}
      </div>
    </form>
  )
}

const inputStyle = {
  width: '100%',
  justifyContent: 'center',
  padding: 0,
  border: 0,
  paddingTop: '2px',
  '&multiLine': {
    input: {
      width: '100%',
      backgroundColor: '#f8f9fa',
      outline: 0,
      border: 'none',
      paddingTop: '2px'
    },
    highlighter: {
      padding: 1,
      border: '2px inset black'
    }
  },
  suggestions: {
    list: {
      backgroundColor: '#ffffff',
      borderRadius: '0.5rem',
      fontSize: 14,
      boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
      padding: '6px'
    },
    item: {
      color: '#495057',
      padding: '9px',
      borderRadius: '0.5rem',
      '&focused': {
        backgroundColor: '#F5F6F7'
      }
    }
  }
}
