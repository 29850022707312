import React from 'react'

import {FormContext} from 'components/Form'
import {FieldCard} from 'components'

const fieldIsOnCard = (Component: React.ComponentType<any>) =>
  (props: any) => {
    const {field} = props
    if (!field.isOnCard) {
      return <Component {...props} />
    }

    const formContext = React.useContext(FormContext)

    return (
      <FieldCard
        name={field.name}
        value={formContext.values.fields[field.name]}
      >
        <Component {...props} />
      </FieldCard>
    )
  }

export default fieldIsOnCard
